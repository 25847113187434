import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import useKycPopup from 'src/hooks/use-kycPopup';
import { openDialog } from 'src/store/dialog/actions';
import { RootState } from 'src/types/store-types';
import '../styles.scss';

const CyberSportFrame: FC<FrameTypes> = ({ iframeSrc, iframeId, iframeClasses }) => {
  const { kycStatus, user } = useSelector((state: RootState) => state.user);
  const { section } = useSelector((state: RootState) => state.accountModal);
  const _openDialog = useConnectedAction(openDialog);
  useKycPopup(kycStatus, user, section, _openDialog);

  return <iframe className={iframeClasses} id={iframeId} src={iframeSrc.loggedIn} allowFullScreen></iframe>;
};

export default CyberSportFrame;
