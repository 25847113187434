import { useEffect } from 'react';
import { EnumDialogsKeys, EnumStatusesOfKyc } from '../store/dialog/types';

const useKycPopup = (
  kycStatus: string,
  user: Record<string, string>,
  section: string,
  _openDialog: (props: any) => void
): void => {
  useEffect(() => {
    if (
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      !section &&
      [EnumStatusesOfKyc.PENDING, EnumStatusesOfKyc.EXPIRED, EnumStatusesOfKyc.REJECTED, undefined].includes(
        kycStatus as EnumStatusesOfKyc | undefined
      )
    ) {
      const interval = setInterval(() => {
        _openDialog({
          dialogType: EnumDialogsKeys.KYC_POPUP,
          dialogProps: { data: {} },
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [kycStatus, user, section]);
};

export default useKycPopup;
